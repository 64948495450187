import { createWebHashHistory, createRouter } from "vue-router";
import store from "./store/index";
import { createToaster } from "@meforma/vue-toaster";
import LoginService from "./services/LoginService";

const toaster = createToaster({
  type: "warning",
  position: "top-right",
});

const isLogin = localStorage.getItem("isLogin");

const rejectAuthUser = (to, from, next) => {
  console.log("isLogin", isLogin);
  if (isLogin === true) {
    toaster.show(`현재 로그인이 되어 있습니다.`);
    next();
  } else {
    next();
  }
};

const onlyAuthUser = (to, from, next) => {
  if (isLogin === false) {
    toaster.show(`로그인을 다시 해주시기 바랍니다.`);
    next("/");
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    alias: "/login",
    name: "login",
    beforeEnter: rejectAuthUser,
    component: () => import("./components/Login"),
  },
  {
    path: "/dashboard",
    alias: "/dashboard",
    name: "dashboard",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Dashboard"),
  },
  {
    path: "/notice",
    alias: "/notice",
    name: "notice",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Notice"),
  },
  {
    path: "/photo",
    alias: "/photo",
    name: "photo",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Photo"),
  },
  {
    path: "/reports",
    alias: "/reports",
    name: "reports",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ClassReport"),
  },
  {
    path: "/takephoto",
    alias: "/takephoto",
    name: "takephoto",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/takePhoto"),
  },
  {
    path: "/studentlist",
    alias: "/studentlist",
    name: "studentlist",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/StudentList"),
  },
  {
    path: "/attendance",
    alias: "/attendance",
    name: "attendance",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendance"),
  },
  {
    path: "/memberList",
    alias: "/memberList",
    name: "memberList",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/MemberList"),
  },
  {
    path: "/attendtotal",
    alias: "/attendtotal",
    name: "attendtotal",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendtotal"),
  },
  {
    path: "/attendallcount",
    alias: "/attendallcount",
    name: "attendallcount",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendallcount"),
  },
  {
    path: "/membergoout",
    alias: "/membergoout",
    name: "membergoout",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/MemberGoout"),
  },
  {
    path: "/personreport",
    alias: "/personreport",
    name: "personreport",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/PersonReport"),
  },
  {
    path: "/newsletter",
    alias: "/newsletter",
    name: "newsletter",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Newsletter"),
  },
  {
    path: "/pray",
    alias: "/pray",
    name: "pray",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Pray"),
  },


  /*********************** */
  {
    path: "/trgtRegister",
    alias: "/trgtRegister",
    name: "trgtRegister",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/TrgtRegister"),
  },
  {
    path: "/manageMgmt",
    alias: "/manageMgmt",
    name: "manageMgmt",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ManageMgmt"),
  },
  {
    path: "/stateview",
    alias: "/stateview",
    name: "stateview",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ManageAllView"),
  },
  {
    path: "/realmgmt",
    alias: "/realmgmt",
    name: "realmgmt",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ManageRealtime"),
  },
  {
    path: "/dummy",
    alias: "/dummy",
    name: "dummy",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Dummy"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./components/Logout"),
  },
  {
    path: "/chart",
    name: "chart",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/BarChart"),
  },
  {
    path: "/exceldownload",
    name: "exceldownload",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ExcelDownload"),
  },
  {
    path: "/exceldownload_dept",
    name: "exceldownload_dept",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ExcelDownload_Dept"),
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("./components/Sample"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  // 브라우저 새로고침 시 로컬 스토리지에서 로그인 상태를 확인
  const isLogin = localStorage.getItem("isLogin");
  const phone = localStorage.getItem("phone");
  const classid = localStorage.getItem("classid");

  const userData = localStorage.getItem('userData');

  //const classidaa = store.state.userData.classid;

  //console.log("classid ==== ", classidaa);

  if (isLogin === "true") {
    const data = {
      phone: phone,
      classid: classid,
    };

    if (phone !== null) {
      LoginService
        .retryLogin(data)
        .then((response) => {
          console.log(" retryLogin response == ", response);
          //store.dispatch('login', response.data[0]); // Vuex를 사용하여 로그인 상태 업데이트
          //store.commit("loginToken", response.data[0]); // store의 로그인 상태를 업데이트


          if (userData) {
            store.commit('setLoginState', response.data[0]);
          }

        })
        .catch((e) => {
          console.log(e);
          this.$toast.show("다시 로그인해주세요.", {
            type: "warning",
            position: "top-right",
          });
        });
    }
  }

  // 다음 라우트로 이동
  next();
});

export default router;


