import http from "../http-common";

class LoginService {
  /** start */
  findTelNumber(data) {
    return http.post(`/api/findTel.php`, data, {
      headers: { "Content-Type": `application/json` },
    });
  }

  sendSms(data) {
    return http.post(`/api/AuthoSmSSend.php/`, data, {
      headers: { "Content-Type": `application/json` },
    });
  }

  changepasswd(data) {
    return http.post(`/api/changepwd.php`, data, {
      headers: { "Content-Type": `application/json` },
    });
  }

  sendTel(data) {
    return http.post(`/api/login.php`, data, {
      headers: { "Content-Type": `application/json` },
    });
  }

  memberRegister(data) {
    return http.post(`/api/memberRegister.php/`, data, {
      headers: { "Content-Type": `application/json` },
    });
  }

  retryLogin(data) {
    //로그인 유지를 위한 체크 
    return http.post(`/api/retryLogin.php`, data, {
      headers: { "Content-Type": `application/json` },
    });
  }

  /** end */

  getDept() {
    return http.post(`/master/dept/`);
  }
}

export default new LoginService();
