<template>
  <div>
    <!-- 메뉴 -->
    <nav
      v-show="$store.state.isLogin"
      class="navbar navbar-expand-lg navbar-dark bg-primary"
      id="navsmenu"
    >
      <span
        style="color: white; font-weight: 600; font-size: 20px; width: 40%"
        @click="this.$router.push('./personreport')"
        data-toggle="collapse"
        data-target="#navbarText"
        >청소년부 만나 출석</span
      >
      <div style="width: 40%; text-align: right; color: white">
        ♡
        {{ name }} 학생
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./notice')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              공지사항
            </button>
          </li>

          <li class="nav-item">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./personreport')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              출석현황
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="$store.state.isLogin"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="logout"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              로그아웃
            </button>
          </li>
        </ul>
        <span class="navbar-text"> </span>
      </div>
    </nav>
    <!-- 메뉴 -->

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
  export default {
    name: "app",
    data() {
      return {
        // id: this.$store.state.id ? this.$store.state.id : localStorage.getItem("id"),
        // grade: this.$store.state.grade ? this.$store.state.grade : localStorage.getItem("grade"),
        // phone: this.$store.state.phone ? this.$store.state.phone : localStorage.getItem("phone"),
        // name: this.$store.state.name ? this.$store.state.name : localStorage.getItem("name"),
        // teacherid: this.$store.state.teacherid ? this.$store.state.teacherid : localStorage.getItem("teacherid"),
        // role: this.$store.state.role,
        // token: this.$store.state.token,
        // classid: this.$store.state.classid ? this.$store.state.classid : this.$cookies.get("classid"),

        // id: this.$store.state.id,
        // grade: this.$store.state.grade,
        // phone: this.$store.state.phone,
        // name: this.$store.state.name,
        // teacherid: this.$store.state.teacherid,
        // role: this.$store.state.role,
        // token: this.$store.state.token,
        // classid: this.$store.state.classid ? this.$store.state.classid : this.$cookies.get("classid"),
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,
        classid: this.$store.state.classid
          ? this.$store.state.classid
          : this.$cookies.get("classid"),
        isLogin: false,

        // id  : JSON.parse(localStorage.getItem('userData')).id ?  localStorage.getItem("id") : this.$store.state.id,
        // grade  : JSON.parse(localStorage.getItem('userData')).grade ?  localStorage.getItem("grade") : this.$store.state.grade,
        // phone  : JSON.parse(localStorage.getItem('userData')).phone ?  localStorage.getItem("phone") : this.$store.state.phone,
        // name   : JSON.parse(localStorage.getItem('userData')).name ?  localStorage.getItem("name") : this.$store.state.name,
        // teacherid  : JSON.parse(localStorage.getItem('userData')).teacherid ?  localStorage.getItem("teacherid") : this.$store.state.teacherid,
        // role  : JSON.parse(localStorage.getItem('userData')).role ?  localStorage.getItem("role") : this.$store.state.role,
        // token  : JSON.parse(localStorage.getItem('userData')).token ?  localStorage.getItem("token") : this.$store.state.token,
        // classid  : JSON.parse(localStorage.getItem('userData')).classid ?  localStorage.getItem("classid") : this.$store.state.classid,
      };
    },
    created() {
      this.initLogin();
    },
    computed: {
      showElement() {
        return this.id != null;
      },
      isLoggedin() {
        return this.$store.state.isLoggedin;
      },
      isAuthenticated() {
        let isLocalAuthenticated = false;
        const userData = localStorage.getItem("userData");
        isLocalAuthenticated = this.$store.state.isAuthenticated;
        if (userData) {
          isLocalAuthenticated = true;
        } else {
          isLocalAuthenticated = false;
        }
        console.log("isAuthenticated == ", this.$store.state.isAuthenticated);
        return isLocalAuthenticated;
      },
    },
    methods: {
      initLogin() {
        // localStorage에서 로그인 상태를 가져옵니다.
        const isLogin = JSON.parse(localStorage.getItem("userData"));
        //console.log("this.isLogin" ,  isLogin.id);
        // localStorage에 로그인 상태가 저장되어 있으면 이를 사용합니다.
        if (isLogin !== null && isLogin.id) {
          this.$store.state.isLogin = true;
          this.id = isLogin.id;
          this.grade = isLogin.grade;
          this.phone = isLogin.phone;
          this.name = isLogin.name;
          this.teacherid = isLogin.teacherid;
          this.role = isLogin.role;
          this.token = isLogin.token;
          this.classid = isLogin.classid;
        } else {
          this.$store.state.isLogin = false;
          this.$store.state.name = "";
          this.$store.state.role = "";
          this.$store.state.grade = "";
        }
      },

      async logout() {
        await this.$store.dispatch("logout");

        localStorage.removeItem("id");
        localStorage.removeItem("grade");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("teacherid");
        localStorage.removeItem("role");
        localStorage.removeItem("classid");
        localStorage.removeItem("isLogin");
        localStorage.removeItem("userData");
        localStorage.clear();

        this.$store.dispatch("logout");
        this.$cookies.remove("phone");
        this.$cookies.remove("classid");
        this.$store.commit("logout");

        this.$router.push({ path: "/login" }).then(() => {
          window.location.reload();
        });
      },
    },
  };
</script>
<style>
  body {
    font-family: "Noto Sans KR", "Helvetica Neue", sans-serif;
  }
  a {
    color: #369;
    text-decoration: none;
  }
</style>

<!-- 
          <li class="nav-item">
            <button
              v-show="userInfo"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./exceldownload_dept')"
            >
            자료 다운로드
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="userInfo && grade > 1"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./exceldownload')"
            >
              Excel Download(PMO)
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="userInfo && grade == 3"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./sample')"
            >
              Sample
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="userInfo"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./chart')"
            >
              통계
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="isLogin"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 80px; font-size: 1em; font-weight: 500"
              @click="this.$store.dispatch('logout')"
            >
              Logout
            </button>
          </li>-->
