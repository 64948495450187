import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "./router";
import "bootstrap-icons/font/bootstrap-icons.css";
import Toaster from "@meforma/vue-toaster";
import store from "./store/index";
import dotenv from "dotenv";
import VueExcelXlsx from "vue-excel-xlsx";
import VueCookies from "vue3-cookies";
import Paginate from "vuejs-paginate-next";
import './registerServiceWorker'
//import "./registerServiceWorker";
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage} from "firebase/messaging";

dotenv.config();

//console.log(process.env)

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyA2APWQwZF3p5Lrcpu7J0M0bEyaBtwNqPM",
//   authDomain: "dongan-manna-be55a.firebaseapp.com",
//   projectId: "dongan-manna-be55a",
//   storageBucket: "dongan-manna-be55a.appspot.com",
//   messagingSenderId: "1092189870239",
//   appId: "1:1092189870239:web:472958beab1547927feaaa",
//   measurementId: "G-4G9Y5JFELJ"
// };


// 알림 수신을 위한 사용자 권한 요청
// Notification.requestPermission()
//   .then((permission) => {
//     console.log('permission ', permission)
//     if (permission !== 'granted') {
//       alert('알림을 허용해주세요')
//     }
//   })

// // Initialize Firebase
// const fb = initializeApp(firebaseConfig);
// // subsequent calls to getToken will return from cache.
// const messaging = getMessaging();
// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // ...
// });
// getToken(messaging, { vapidKey: 'BJhHFU23z_ezxELsTpYnCDrsowf_OgSwFaRqSHpijkcgyvlYVAYhoNEuWiCwkuBFFlckacRgyGZVT8VLGbKpqRg' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     console.log("Token is ",currentToken );
//     localStorage.setItem("FCMToken", currentToken);
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });
// Vuex 스토어 생성

createApp(App)
  .use(router)
  .use(Toaster)
  .use(store)
  .use(VueExcelXlsx)
  //.use(fb)  
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "attend.dongan1718.com",
    secure: false,
    sameSite: "None",
  })
  .component("paginate", Paginate)
  .mount("#app");


